import React from 'react';
import hourglass from "static/svg/hourglass.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl';

export default class WaitingScreen extends React.Component {
    render() {
        return (
            <div className="app-container">
                <div className="container-fluid player-container player-container">
                    <span className="exit-button" onClick={() =>  this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>

                    <div className="row" style={{marginTop:'5rem'}}>
                        <div className="title-container col-md" style={{paddingTop:0, marginBottom:0,}}>
                            <h1 className="app-title"><FormattedMessage id='wait' /></h1>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'25%'}}>
                        <img className="hourglass"  src={hourglass}/>
                    </div>
                </div>
            </div>
        );
    }
}