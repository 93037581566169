import React from 'react';
import type from "static/svg/type.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl'
import HintScreen from '../HintScreen.jsx'


export default class AnswerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: null,
        };
        this.handleAnswer = this.handleAnswer.bind(this);
        this.sendAnswer = this.sendAnswer.bind(this);
        this.inputBlur = this.inputBlur.bind(this);
    }

    inputBlur(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    handleAnswer(e){
        this.setState({answer: e.target.value});
    }
    sendAnswer(){
        let answer = this.state.answer;
        this.props.sendSocketMessage({
            command: 'openQuestion.playerAnswer',
            data: {
                answer: answer,
            }
        });
    }
    render() {
        let {data} = this.props.information;
        let hintContent = "";

        if(Object.keys(data.hints).length > 0){
            hintContent = Object.keys(data.hints).map((index) =>{
                let hint = data.hints[index];
                return (
                    <HintScreen
                        hint={hint}
                    />
                );
            })
        }

        return (
            <div className="app-container">
                <div className="container-fluid scrollable-container player-container">
                <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <img className="hint-icon" style={{marginTop:'5rem'}} src={type}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title-container col-md" style={{paddingTop:'5rem', paddingRight:'5rem', paddingLeft:'5rem', marginBottom:0,}}>
                            <h1 className="app-title">
                                {data.question}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md" style={{paddingTop:'5rem', marginBottom:0,}}>
                            <FormattedMessage id='player.openquestion.answerscreen.placeholder'>
                                {(msg) => (<textarea className="app-open-answer" type="text" maxLength={100} onChange={this.handleAnswer} placeholder={msg} spellCheck="false"  autoCorrect="off" />)}
                            </FormattedMessage>
                        </div>
                    </div>
                    <div className="row" style={{paddingBottom:'1em', marginTop:'15%'}}>
                        <div className="col-md">
                            <button className="orange-button" disabled={this.state.sent} onClick={this.sendAnswer}><FormattedMessage id='readybutton'/></button>
                        </div>
                    </div>
                    {hintContent}
                    <div className="padding" style={{paddingBottom:'20%'}}>
                    </div>
                </div>
                <div className="fade-element"></div>

            </div>
        );
    }
}