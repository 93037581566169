import React, {Component} from 'react';
import leaveGame from "static/svg/leave-game.svg";
import HintScreen from '../HintScreen.jsx'


export default class HintDisplay extends React.Component {
    render() {
        let {data} = this.props.information;
        let hintContent = "";
        if(Object.keys(data.hints).length > 0){
            hintContent = Object.keys(data.hints).map((index) =>{
                let hint = data.hints[index];
                return (
                    <HintScreen
                        hint={hint}
                    />
                );
            })
        }
        return (
            <div className="app-container">
                <div className="container-fluid scrollable-container player-container">
                <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>
                    {hintContent}
                    <div className="padding" style={{paddingBottom:'20%'}}></div>
                </div>
                <div className="fade-element"></div>
            </div>
        );
    }
}