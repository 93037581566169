import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'
import Pulmikas from '../../../../utils/Pulmikaslogo.jsx';

export default class ResultScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: {},
            timeout: null
        };
        this.checkScores = this.checkScores.bind(this);
        this.animateScore = this.animateScore.bind(this);
    }
    componentDidMount() {
        this.state.timeout = setTimeout(() => this.checkScores(), 2000);
    }
    componentDidUpdate() {
        if(typeof(this.state.timeout) == 'undefined') {
            this.state.timeout = setTimeout(() => this.checkScores(), 2000);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.state.timeout);
        for(var key in this.state.interval){
            clearInterval(this.state.interval[key]);
        }
    }

    checkScores() {
        let {groups} = this.props.information.data;
        for(var index in groups){
            let idx = index;
            if(groups[index].score > groups[index].previous_score && typeof(this.state.interval[index]) == 'undefined'){
                this.state.interval[index] = setInterval(() => this.animateScore(idx), 10);
            }
        }
    }

    animateScore(index) {
        let element = document.getElementById(`group-${index}-score-big`);
        let element_add = document.getElementById(`group-${index}-add-big`);
        let {groups} = this.props.information.data;

        if(element.innerHTML == groups[index].score){
            clearInterval(this.state.interval[index]);
            element_add.classList.add('fade-out');

        } else {
            let value = parseInt(element.innerHTML);
            value++;
            element.innerHTML = value;
        }
    }

    render(){
        let {data} = this.props.information;
        const {groups} = this.props.information.data;
        let groupRows = [];
        let rowCount = Object.keys(groups).length > 3 ? 2 : 1;

        groupRows[0] = Object.keys(groups).map((index) => {
            let group = groups[index];
            let avatars;
            let name = group.name;
            let group_text = "";
            let avatarStyle;
            let groupStyle;
            let scoreStyle;

            if(rowCount == 2){
                if(group.answer !== null && group.answer.length > 100){
                    //group.answer.text = group.answer.text.slice(0,100) + "...";
                }
                groupStyle = { verticalAlign:'text-bottom', height:'30%' };
                avatarStyle = {paddingLeft:'5rem', maxHeight:'80%', width:'30rem', height:'15rem', margin:'0 auto'};
                scoreStyle = {height:'10rem', textAlign:'center'}
            } else {
                groupStyle = {marginTop:'5rem', verticalAlign:'text-bottom', height:'50%'};
                avatarStyle = {paddingLeft:'5rem', maxHeight:'80%', width:'35rem', height:'20rem', margin:'0 auto'};
                scoreStyle = {height:'14rem', textAlign:'center'}
            }

            let score_add = group.score - group.previous_score;
            if(index > 3) return;
            
            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>;
                name = index
            }
            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }
            if(group.players){
                avatars = Object.keys(group.players).map((player_id) => {
                    let player = group.players[player_id];
                    if(player.avatar){
                        return(
                            <img src={player.avatar}/>
                        )
                    }
                })
            }
            return (
                <div className="d-flex justify-content-center col-md">
                    <div className={rowCount == 1 ? "screen-big-team" : "screen-med-team"} style={groupStyle}>
                        <div style={avatarStyle}>
                            {avatars}
                        </div>
                        <p className="screen-group-med"> {group_text}  {name} </p>
                        <div style={scoreStyle}>
                            <p id={`group-${index}-score-big`} className="score-big">{group.previous_score !== null ? group.previous_score : group.score}</p>
                            <p id={`group-${index}-add-big`} className="score-add-big">{score_add != 0 ? '+' + score_add : null } </p>
                        </div>
                        <div className="screen-group-answer">
                            <p className="screen-option-text"> {group.answer }</p>
                        </div>
                    </div>
                </div>
            )
        });

        if(rowCount == 2){            
            groupRows[1] = Object.keys(groups).map((index) => {
                let group = groups[index];
                let avatars;
                let name = group.name;
                let group_text = "";
                let avatarStyle;
                let groupStyle;
                let scoreStyle;

                if(rowCount == 2){
                    if(group.answer !== null && group.answer.length > 100){
                        //group.answer.text = group.answer.text.slice(0,100) + "...";
                    }
                    groupStyle = { verticalAlign:'text-bottom', height:'30%' };
                    avatarStyle = {paddingLeft:'5rem', maxHeight:'80%', width:'30rem', height:'15rem', margin:'0 auto'};
                    scoreStyle = {height:'10rem', textAlign:'center'};
                } else {
                    groupStyle = {marginTop:'5rem', verticalAlign:'text-bottom', height:'50%'};
                    avatarStyle = {paddingLeft:'5rem', maxHeight:'80%', width:'35rem', height:'20rem', margin:'0 auto'};
                    scoreStyle = {height:'14rem', textAlign:'center'};
                }

                let score_add = group.score - group.previous_score;
                if(index < 4) return;
                
                if(group.name === null || group.name == ""){
                    group_text = <FormattedMessage id = "group"/>;
                    name = index
                }
                if(name.length > 13){
                    name = name.slice(0,11)+'...';
                }
                if(group.players){
                    avatars = Object.keys(group.players).map((player_id) => {
                        let player = group.players[player_id];
                        if(player.avatar){
                            return(
                                <img src={player.avatar}/>
                            )
                        }
                    })
                }
                return (
                    <div className="d-flex justify-content-center col-md">
                        <div className={rowCount == 1 ? "screen-big-team" : "screen-med-team"} style={groupStyle}>
                            <div style={avatarStyle}>
                                {avatars}
                            </div>
                            <p className="screen-group-med"> {group_text}  {name} </p>
                            <div style={scoreStyle}>
                                <p id={`group-${index}-score-big`} className="score-big">{group.previous_score ? group.previous_score : group.score}</p>
                                <p id={`group-${index}-add-big`} className="score-add-big">{score_add != 0 ? '+' + score_add : null } </p>
                            </div>
                             <div className="screen-group-answer">
                                <p className="screen-option-text"> {group.answer }</p>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            groupRows[1] = null;
        }
        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row" >
                            {groupRows[0]}
                        </div>
                        <div className="row">
                            {groupRows[1]}
                        </div>
                        <div className="row" style={{marginTop:'10rem'}} >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-instruction" style={{width:'70%', fontSize:'7rem'}}>{data.instruction}</p>
                            </div>
                        </div>
                    </div>
                    <Pulmikas screen={true}/>
                    <ScreenStepper
                        data={data}
                    />
                </div>
            </div>
        );
    }
}