import React from 'react';
import hourglass from "static/svg/hourglass.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl'
import ActionButtons from '../../../utils/FacilitatorActions.jsx';


export default class CountdownScreen extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let actionButtons;
        actionButtons = (
            <ActionButtons
                information={this.props.information}
                sendSocketMessage={this.props.sendSocketMessage}
            />
        );

        let infoText;
        if (this.props.information.data.screen_phase !== null)
            switch (this.props.information.data.screen_phase) {
                case 'read':
                    infoText = <FormattedMessage id='facilitator.waitscreen.read' />;
                    break;
                case 'multiquestion_discuss':
                    infoText = <FormattedMessage id='facilitator.waitscreen.multiquestion.discuss' />;
                    break;
                case 'openquestion_discuss':
                     infoText = <FormattedMessage id='facilitator.waitscreen.openquestion.discuss' />;
                    break;
                case 'vote':
                    infoText = <FormattedMessage id='facilitator.waitscreen.vote' />;
                    break;
                case 'vote_results':
                    infoText = <FormattedMessage id='facilitator.waitscreen.voteresults' />;
                    break;
                case 'debate_wheel':
                      infoText = <FormattedMessage id='facilitator.waitscreen.debatewheel' />;
                    break;
                case 'debate_results':
                    infoText = <FormattedMessage id='facilitator.waitscreen.debateresults' />;
                    break;
                case 'fact_result':
                    infoText = <FormattedMessage id='facilitator.factquestion.result' />;
                    break;
                case 'result':
                    infoText = <FormattedMessage id='facilitator.multiquestion.answer.result' />;
                    break;
                default:
                    infoText = null;
                    break;
            }
            let info = (
                <div className="d-flex justify-content-center col-md">
                    <p className="app-paragraph" style={{width: "70%", fontSize: "3rem", marginBottom: "3rem"}}>
                        {infoText}
                    </p>
                </div>
            );

        return (
            <div className="app-container">
                <div className="container-fluid facilitator-container">
                        <span className="exit-button" onClick={() => this.props.exitGame() }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>
                    <div className="row">
                        <div className="title-container col-md" style={{marginTop:'5rem', paddingTop:0, marginBottom:0,}}>
                            <h1 className="app-title"><FormattedMessage id='wait' /></h1>
                        </div>
                    </div>
                    {info}
                    <div className="row">
                        <img className="hourglass" style={{marginTop:'5rem'}} src={hourglass}/>
                    </div>
                    {actionButtons}
                </div>
            </div>
        );
    }
}