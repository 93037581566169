import React from 'react';
import select from "static/svg/select.svg";
import leaveGame from "static/svg/leave-game.svg";
import HintScreen from '../HintScreen.jsx'


export default class AnswerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: null,
        };
        this.sendAnswer = this.sendAnswer.bind(this);
    }
    sendAnswer(value){
        value = value['key'];
        this.setState({answer: value});
        this.props.sendSocketMessage({
            command: 'multiQuestion.playerAnswer',
            data: {
                answer: value,
            }
        });
    }
    render() {
        let {data} = this.props.information;
        let hintContent = "";
        let optionsContent = Object.keys(data.choices).sort().map( (key, index)=> {
            return (<div className="row">
                    <div className="d-flex justify-content-center col-md" style={{paddingTop:'3rem'}}>
                        <div className="app-option-container" onClick={() => this.sendAnswer({key})}>
                            <p className="app-option-index disable-selection">{key}</p>
                            <p className="app-option-text disable-selection"> {data.choices[key].choice_text} </p>
                        </div>
                    </div>
            </div>)
        });
        if(Object.keys(data.hints).length > 0){
            hintContent = Object.keys(data.hints).map((index) =>{
                let hint = data.hints[index];
                return (
                    <HintScreen
                        hint={hint}                
                    />
                );
            })
        }
        return (
            <div className="app-container">
                <div className="container-fluid scrollable-container player-container">
                    <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <img className="hint-icon" style={{marginTop:'5rem'}} src={select}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title-container col-md" style={{paddingTop:'2rem', marginBottom:0,}}>
                            <h1 className="app-title">
                                {data.question}
                            </h1>
                        </div>
                    </div>
                    {optionsContent}
                    {hintContent}
                    <div className="padding" style={{paddingBottom:'20%'}}>
                    </div>
                    </div>
                    <div className="fade-element">
                </div>
            </div>
        );
    }
}