import React, {Component} from 'react';
import EndGameScreen from './EndGame.jsx';
import RateScreen from './RateScreen.jsx';

export default class GameEndHandler extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            case("end"):
                viewComponent = (
                    <EndGameScreen
                        information={this.props.information}
                        exitGame={this.props.exitGame}
                        sendSocketMessage={this.props.sendSocketMessage}

                    />
                );
                break;
            case("rate"):
                viewComponent = (
                    <RateScreen
                        information={this.props.information}
                        exitGame={this.props.exitGame}
                        sendSocketMessage={this.props.sendSocketMessage}

                    />
                );
                break;
            default:
                viewComponent = null;
                break;

        }
            return (
                <div>
                    {viewComponent}
                </div>
            );
    }
}