import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import AnswerModeration from './AnswerModeration.jsx';
import CountdownScreen from '../CountdownScreen.jsx';
import DebateModeration from './DebateModeration.jsx';

export default class MultiQuestionHandler extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let {data} = this.props.information;
        let viewComponent = "";

        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            //multiQuestion.answer
            case("answer"):
                viewComponent = (
                    <AnswerModeration
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        exitGame={this.props.exitGame}
                    />
                );
                break;
            //multiQuestion.countdown
            case("countdown"):
                viewComponent = (
                    <CountdownScreen
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                    exitGame={this.props.exitGame}
                />
                );
                break;
            //multiQuestion.debate
            case("debate"):
                viewComponent = (
                    <DebateModeration
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                    exitGame={this.props.exitGame}
                />
                );
                break;
                
        }
            return (
                <div>
                    {viewComponent}
                </div>
            );
    }
}