import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

export default class ScreenStepper extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let {data} = this.props;
        let steps = [];

        for(var step = 1; step <= data.maxsteps; step++){
            steps.push(step);

        }

        steps = steps.map((step, index) =>{
            return (
                <span>
                <span className="stepper-number" style={step <= data.step ? {backgroundColor:'#f7931d'} : {backgroundColor:'#38a3bb'}}> {step} </span>
                {step != data.maxsteps ? <span className="stepper-line"/>: null}
                </span>
            )
        })
       
        return (
            <div className="screen-stepper">
                {steps}
            </div>
        );
    }
}