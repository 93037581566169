import React from 'react';
import {Redirect} from 'react-router-dom';

export default class End extends React.Component {
	constructor(props) {
        super(props);
		if (document.cookie.indexOf('opintokamusimulaatiopeli_UID=') !== -1){
			document.cookie = 'opintokamusimulaatiopeli_UID=' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		}
	};
	render() {
		 return <Redirect
			 push to={{
			 	 pathname: '/screen',
			 }}
		 />;
	}
}