import React, {Component} from 'react';
import CountdownScreen from '../CountdownScreen.jsx';

export default class SliderHandler extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];

        switch(viewName){
            //openQuestion.hint
            case("countdown"):
                viewComponent = (
                    <CountdownScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        exitGame={this.props.exitGame}
                    />
                );
             break;
        }
        return (
            <div>
                {viewComponent}
            </div>
        );
    }
}