import React, {Component} from 'react';
import PictureScreen from './PictureScreen.jsx';
import StoryScreen from './StoryScreen.jsx';

export default class SliderHandler extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            //openQuestion.hint
            case("picture"):
                viewComponent = (
                    <PictureScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        exitGame={this.props.exitGame}
                    />
                );
                break;

            //openQuestion.answer
            case("story"):
                viewComponent = (
                    <StoryScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        exitGame={this.props.exitGame}
                    />
                );
                break;
        }
            return (
                <div>
                    {viewComponent}
                </div>
            );
    }
}