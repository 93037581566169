import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import Pulmikas from '../../../utils/Pulmikaslogo.jsx';

export default class WelcomeScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {groups} = this.props.information.data;
        let teamRow;

        teamRow = Object.keys(groups).map((index) => {
            let name = groups[index].name;
            let group = groups[index];
            let group_text = "";
            let avatars = "";

            if (group.name === null || group.name == "") {
                group_text = <FormattedMessage id="group"/>
                name = index
            }

            if (group.players) {
                avatars = Object.keys(group.players).map((player_id) => {
                    let player = group.players[player_id]
                    if (player.avatar) {
                        return (
                            <img src={player.avatar} style={{height: '10rem', width: '10rem',}}/>
                        )
                    }
                })
            }

            return (
                <div className="screen-group-avatars" style={{verticalAlign: 'text-bottom', maxWidth: '16%'}}>
                    <div style={{paddingLeft: '5%', height: '80%'}}>
                        {avatars}
                    </div>
                    <p className="screen-group-small" style={{fontSize: '4rem'}}> {group_text} {name} </p>
                </div>
            )
        });

        return (
            <div className="screen-container">
                <div className="container-fluid">
                    <Pulmikas/>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="screen-title" style={{paddingTop: '4rem', paddingBottom: '2rem'}}>
                                <FormattedMessage id='screen.welcomescreen.title'/></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <p className="screen-paragraph"><FormattedMessage id='screen.welcomescreen.instruction'/>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <input className="game-select-screen" type="text" disabled maxLength="4"
                                   style={{marginTop: '0.6em', width: '7em'}} onChange={this.updateState}
                                   value={this.props.information.data.player_code}/>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '16rem'}}>
                        <div className="d-flex justify-content-center col-md">
                            {teamRow}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}