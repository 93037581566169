import React from "react";
import Logo from 'static/images/Pulmikas_logo_hohto_valkoinen.png';

export default (props) => {
    if (props.screen) {
        return (
        <div className={"row"} style={{
                position: 'absolute',
                bottom: '3rem',
                left: '0',
                right: '0',
            }}>
            <div className="col-md">
                <div className={"text-left"}>
                    <img src={Logo} height="60" alt="Pulmikas-logo"/>
                </div>
            </div>
        </div>
        )
    }
    return (
        <div className="row" style={{paddingTop: '5em'}}>
            <div className="col-md">
                <div className={"text-center"}>
                    <img src={Logo} height="120" alt="Pulmikas-logo"/>
                </div>
            </div>
        </div>
    )
}
