import React, {Component} from 'react';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'
import {FormattedMessage} from 'react-intl';

export default class GameStart extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let {data} = this.props.information;
        let imageStyle;

        imageStyle= data.background_image ? { backgroundImage:`url(${data.background_image})`, backgroundRepeat:'no-repeat', backgroundPosition:'top center', backgroundSize:'cover'} : null;
        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column" style={imageStyle}>
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row picture-screen-title" >
                            <div className="d-flex justify-content-center col-md">
                                <h1 className="screen-title" style={{fontSize:'12rem'}}> {data.content} </h1>
                            </div>
                        </div>
                    </div>
                    <ScreenStepper
                        data={data}
                    />
                </div>
            </div>
        );
    }
}