import React, {Component} from 'react';
import SetupScreen from './SetupBigScreen.jsx';
import GroupModeration from './GroupModeration.jsx';

export default class GroupBuildingHandler extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let moduleView = null;
        // Get the module name from view string
        let viewName = this.props.information.view.split('.').slice(-1)[0];
		switch(viewName) {
			case 'waitingForStart':
				moduleView = (
				    <SetupScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        exitGame={this.props.exitGame}
                    />
                );
				break;
			case 'groupModeration':
				moduleView = (
				    <GroupModeration
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        exitGame={this.props.exitGame}
                    />
                );
				break;
            default:
				moduleView = null;
		}
		return (<div>{moduleView}</div>);
	}
}
