import React from 'react';
import speechBubbles from "static/svg/speech-bubbles.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl'


export default class DiscussScreen extends React.Component {

    render() {

        return (
            <div className="app-container">
            <div className="container-fluid scrollable-container player-container">
                <span className="exit-button" onClick={() =>  this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>

                <div className="row" style={{marginTop:'5rem'}}>
                    <div className="title-container col-md" style={{paddingTop:0, marginBottom:0,}}>
                        <h1 className="app-title"><FormattedMessage id='discuss' /></h1>
                    </div>
                </div>
                <div className="row" style={{marginTop:'25%'}}>
                    <img className="hourglass" src={speechBubbles}/>
                </div>
            </div>
            </div>
        );
    }
}