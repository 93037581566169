import React, {Component} from 'react';
import AnswerScreen from './AnswerScreen.jsx';
import HintDisplay from './HintDisplay.jsx';
import WaitScreen from '../WaitingScreen.jsx';
import DebateScreen from '../DebateScreen.jsx'
import ListenScreen from '../ListenScreen.jsx'
import DiscussScreen from '../DiscussScreen.jsx';

export default class MultiQuestionHandler extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            //multiQuestion.hint
            case("hint"):
                viewComponent = (
                    <HintDisplay
                        information={this.props.information}
                        module="multiQuestion"
                        exitGame={this.props.exitGame}
                    />
                );
                break;
            //multiQuestion.answer
            case("answer"):
                viewComponent = (
                    <AnswerScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        module="multiQuestion"
                        exitGame={this.props.exitGame}
                    />
                );
                break;
            //multiQuestion.listen
            case("listen"):
                viewComponent = (
                    <ListenScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        module="multiQuestion"
                        exitGame={this.props.exitGame}
                    />
                );
                break;
            //multiQuestion.debate
            case("debate"):
                viewComponent = (
                    <DebateScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        module="multiQuestion"
                        exitGame={this.props.exitGame}
                    />
                );
                break;
            //multiQuestion.wait
            case("wait"):
                viewComponent = (
                    <WaitScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        module="multiQuestion"
                        exitGame={this.props.exitGame}
                    />
                );
                break;
            //multiQuestion.discuss
            case("discuss"):
                viewComponent = (
                    <DiscussScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        module="multiQuestion"
                        exitGame={this.props.exitGame}
                    />
                );
                break;
        }
            return (
                <div>
                    {viewComponent}
                </div>
            );
    }
}