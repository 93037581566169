import React, {Component} from 'react';
import AnswerScreen from './AnswerScreen.jsx';
import QuestionScreen from './QuestionScreen.jsx';

//Styles
import 'rc-slider/assets/index.css';

export default class FactQuestionHandler extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let viewComponent = "";

        let viewName = this.props.information.view.split('.').slice(-1)[0];
        //factQuestion.question
        if(viewName=="question"){
            viewComponent = (
                <QuestionScreen
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                />
            )
        }
        //factQuestion.answer
        else if(viewName=="answer"){
            viewComponent = (
                <AnswerScreen
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                />
            )

        }
            return (
                <div className ="screen-container">
                    {viewComponent}
                </div>
            );
    }
}