import React from 'react';
import speechBubbles from "static/svg/speech-bubbles.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl'


export default class HintScreen extends React.Component {
    render() {
        let hint;
        if(this.props.hint){
            hint = this.props.hint;
        } else {
            let data = this.props.information.data;
            hint = data.hint;
        }
        return (
            <div className={this.props.hint ? null : "app-container"}>
                <div className={this.props.hint ? null : "container-fluid player-container"}>
                    {this.props.hint ? null : <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>}
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <img className="hint-icon" style={{marginTop:'5rem'}} src={speechBubbles}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title-container col-md" style={{paddingTop:'2rem', marginBottom:0,}}>
                            <h1 className="app-title"><FormattedMessage id='hint' /></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md" style={{paddingLeft:'10%', paddingRight:'10%', paddingTop:'5rem'}}>
                            <p className="hint-text">
                                {hint}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}