import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'

export default class StoryScreen extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let {data} = this.props.information;
        let {groups} = this.props.information.data;
        let scoreRow;

        scoreRow = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;
            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/> 
                name = index
            }
            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }
            return(
                <p className="screen-group-small"> {group_text} {name} <span className="score"> {group.score} </span> </p>
            )
        });
        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            {scoreRow}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row" >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-description" style={{width:'70%'}} >
                                    {data.content.split('\n').map((item, key) => {
                                        return <span key={key}>{item}<br/></span>
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}