import React, {Component} from 'react';
import RateScreen from './RateScreen.jsx';


export default class GameEndHandler extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            case("rate"):
                viewComponent = (
                    <RateScreen
                        information={this.props.information}
                        exitGame={this.props.exitGame}
                        module="gameEnd"
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
        }
            return (
                <div>
                    {viewComponent}
                </div>
            );
    }
}