import { combineReducers } from 'redux';
import languagesReducer from './LanguageReducer.js'


const appReducer = combineReducers({
    languagesReducer, 
});


export default appReducer;
