import React, {Component} from 'react';
import WaitingScreen from '../WaitingScreen.jsx';

export default class SliderHandler extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            //openQuestion.hint
            case("wait"):
                viewComponent = (
                    <WaitingScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                        module="slider"
                        exitGame={this.props.exitGame}
                    />
                );
            break;
        }
        return (
            <div>
                {viewComponent}
            </div>
        );
    }
}