import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import 'styles/fireworks.scss'
import Pulmikas from 'utils/Pulmikaslogo.jsx';

export default class EndScoreScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render(){

        const {groups} = this.props.information.data;
        let groupRows = [];
        let groupsArray;

        let rowCount = Object.keys(groups).length > 3 ? 2 : 1;
        groupsArray = Object.keys(groups).map((index) => {
            return [index, groups[index]]
        });

        groupsArray.sort((a,b) => {
            return parseInt(b[1].score) - parseInt(a[1].score)
        });

        groupsArray = groupsArray.map((group, index) => {
            groupsArray[index][1].groupIndex = group[0]
            return group[1];
        });

        groupsArray[0] = groupsArray.splice(1, 1, groupsArray[0])[0];
        groupRows[0] = Object.keys(groupsArray).map((index) => {
            let group = groupsArray[index];
            let avatars;
            let name = group.name;
            let group_text = "";
            let avatarStyle;
            let avatarContainerStyle;
            let groupStyle;
            let scoreStyle;
            let placement;
            let placementStyle;
            switch(parseInt(index)){
                case(0):
                    placementStyle={fontSize:'30rem', paddingTop:'8rem'};
                    avatarStyle={height:'10rem', width:'10rem',};
                    avatarContainerStyle={width:'35rem', height:'21rem'};
                    groupStyle={fontSize:'6rem'};
                    scoreStyle={fontSize:'10rem'};
                    placement=2;
                    break;
                case(1):
                    placementStyle={fontSize:'40rem'};
                    avatarStyle={height:'12rem', width:'12rem',};
                    avatarContainerStyle={width:'47rem', height:'25rem'};
                    groupStyle={fontSize:'9rem'};
                    scoreStyle={fontSize:'14rem'};
                    placement=1;
                    break;
                case(2):
                    placementStyle={fontSize:'25rem', paddingTop:'12rem'};
                    avatarStyle={height:'8rem', width:'8rem',};
                    avatarContainerStyle={width:'30rem', height:'17rem'};
                    groupStyle={fontSize:'5rem'};
                    scoreStyle={fontSize:'6rem'};
                    placement=3;
                    break;
            }
            if(index > 2) return;
            
            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/> 
                name = group.groupIndex;
            }

            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }

            if(group.players){
                avatars = Object.keys(group.players).map((player_id) => {
                    let player = group.players[player_id];

                    if(player.avatar){
                        return(
                            <img style={avatarStyle} src={player.avatar}/>
                        )
                    }
                })
            }
            return(
                <div className="d-flex justify-content-center col-md">
                    <div className='screen-team-placement'>
                        <h1 className="placement-number" style={placementStyle}>{placement}</h1>
                        <div className="avatars" style={avatarContainerStyle}>
                            {avatars}
                        </div>
                        <p className="placement-group" style={groupStyle}> {group_text} {name} </p>
                        <div className="placement-scores">
                            <p className="placement-score" style={scoreStyle}>{group.score}</p>
                        </div>
                    </div>
                </div>
            )
        });

        if(rowCount == 2){            
            groupRows[1] = Object.keys(groupsArray).map((index) => {
                let group = groupsArray[index];
                let avatars;
                let name = group.name;
                let group_text = "";
                let avatarStyle;
                let groupStyle;
                let scoreStyle;

                if(index < 3) return;
                
                if(group.name === null || group.name == ""){
                    group_text = <FormattedMessage id = "group"/> 
                    name = group.groupIndex
                }
    
                if(name.length > 13){
                    name = name.slice(0,11)+'...'
                }  

                return(
                    <div style={{height:'7rem'}}>
                        <p className="screen-group-small"> {group_text} {name}
                            <span id={`group-${index}-score`} className="score">{group.score}</span> 
                        </p>
                    </div>
                )
            })
        } else {
            groupRows[1] = null;
        }
        return (
            <div className="screen-container">
                <div className="container-fluid">
                    <div className="row" >
                        {groupRows[0]}
                    </div>
                    <div className="row" style={{position:'absolute', bottom:'5rem', left:0, right:0, margin:'auto'}}>
                        <div className="d-flex justify-content-center col-md">
                        {groupRows[1]}
                        </div>
                    </div>
                    <Pulmikas screen={true}/>
                </div>
                <div className="pyro" style={{height:'100%', width:'100%', position:'absolute', top:'0'}}>
                <div className="before"></div>
                <div className="after"></div>
                </div>
            </div>
        );
    }
}