import React, {Component} from 'react';
import AnswerScreen from './AnswerScreen.jsx';
import QuestionScreen from './QuestionScreen.jsx';
import IntroductionScreen from './IntroductionScreen.jsx';
import DebateScreen from './DebateScreen.jsx';
import DebateWheel from './DebateWheel.jsx';
import ResultScreen from './ResultScreen.jsx';

export default class MultiQuestionHandler extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let {data} = this.props.information;
        let viewComponent = "";
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        switch(viewName){
            //multiQuestion.introduction
            case("introduction"):
                viewComponent = (
                    <IntroductionScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            //multiQuestion.question
            case("question"):
                viewComponent = (
                    <QuestionScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            //multiQuestion.answer
            case("answer"):
                viewComponent = (
                    <AnswerScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            //multiQuestion.result
            case("result"):
                viewComponent = (
                    <ResultScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            //multiQuestion.debateWheel
            case("debateWheel"):
                viewComponent = (
                    <DebateWheel
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            //multiQuestion.debate
            case("debate"):
                viewComponent = (
                    <DebateScreen
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    />
                );
                break;
            default:
                viewComponent = null;
                break;
                
        }
            return (
                <div className ="screen-container">
                    {viewComponent}
                </div>
            );
    }
}